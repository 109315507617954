import React from "react";

import TopNavbar from "../components/navbar/TopNavbar";

const RootLayout = ({ children }) => {
  return (
    <div className="w-100 ">
      <TopNavbar />
      <main className="py-3">{children}</main>
    </div>
  );
};

export default RootLayout;
