import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProjectReport } from "../../helper/backend_helper";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Container } from "react-bootstrap";

import _ from "lodash";
import MySpinner from "../../components/common/MySpinner";
import SearchInput from "../../components/common/SearchInput";
import BackButton from "../../components/common/BackButton";

import FormateCurrency from "../../components/common/FormateCurrency";
const ShowReportDetails = () => {
  const { projectId, reportId } = useParams();

  const [filtered, setFiltered] = useState([]);

  const [reportTotalData, setReportTotalData] = useState({});
  const [reportDetails, setReportDetails] = useState([]);

  // filter
  const [filterText, setFilterText] = useState("");

  // loading
  const [fetching, setFetching] = useState(false);

  const amountBodyTemplate = ({ profit, loss, amount }) => {
    const className =
      (profit > 0 && "text-success") || (loss < 0 && "text-danger");

    const icon =
      (profit > 0 && <i className="ri-arrow-drop-up-fill"></i>) ||
      (loss < 0 && <i className="ri-arrow-drop-down-fill"></i>);

    return (
      <FormateCurrency amount={amount} className={className} icon={icon} />
    );
  };

  const fetchData = async () => {
    try {
      setFetching(true);
      const response = await getProjectReport({ projectId, reportId });

      if (response.status === 200 && response?.data?.data) {
        // format report content
        const formattedData = response.data.data?.details?.map(
          (record, index) => {
            return {
              _id: record._id,
              no: index + 1,
              countryName: record.countryName,
              earningInINR: record.earningInINR,
              spendingInINR: record.spendingInINR,
              profit: record.profit,
              loss: record.loss,
            };
          }
        );

        setFiltered(formattedData);
        setReportDetails(formattedData);

        // format report total data
        setReportTotalData(() => {
          const className =
            ((response.data.data?.totalProfitInINR || 0) > 0 &&
              "text-success") ||
            ((response.data.data?.totalLossInINR || 0) < 0 && "text-danger");

          const icon =
            ((response.data.data?.totalProfitInINR || 0) > 0 && (
              <i className="ri-arrow-drop-up-fill"></i>
            )) ||
            ((response.data.data?.totalLossInINR || 0) < 0 && (
              <i className="ri-arrow-drop-down-fill"></i>
            ));

          console.log(response.data.data);
          return {
            totalEarningInINR: response.data.data?.totalEarningInINR || 0,
            totalSpendingInINR: response.data.data?.totalSpendingInINR || 0,
            totalProfitInINR: response.data.data?.totalProfitInINR || 0,
            totalLossInINR: response.data.data?.totalLossInINR || 0,
            profitInINR: response.data.data?.profitInINR || 0,
            lossInINR: response.data.data?.lossInINR || 0,
            className: className,
            icon: icon,
          };
        });
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setFetching(false);
    }
  };

  // fetch data
  useEffect(() => {
    fetchData();
  }, []);

  // filter data
  useEffect(() => {
    const filterData = () => {
      if (filterText && reportDetails.length > 0) {
        const result = reportDetails.filter((item) => {
          return item.countryName
            .toLowerCase()
            .includes(filterText.toLocaleLowerCase());
        });
        setFiltered(() => result);
      } else {
        setFiltered(reportDetails);
      }
    };

    const debouncedFilterData = _.debounce(filterData, 300);

    debouncedFilterData();
    return () => debouncedFilterData.cancel();
  }, [filterText, reportDetails]);

  return (
    <Container>
      <div className="d-flex align-items-center w-100 p-3 bg-white">
        <BackButton />
        <h5>Report Details</h5>
        <SearchInput
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
      </div>

      {fetching ? (
        <MySpinner />
      ) : (
        <DataTable
          sortField="no"
          sortOrder={1}
          size={"normal"}
          className="firstTdSticky"
          value={filtered}
          tableStyle={{ minWidth: "50rem", fontSize: "14px" }}
          footerColumnGroup={
            <ColumnGroup>
              <Row>
                <Column sortable footer="Sum" colSpan={4} />

                <Column
                  footer={
                    <FormateCurrency
                      amount={reportTotalData.profitInINR}
                      className={"text-success"}
                      icon={reportTotalData.icon}
                    />
                  }
                />
                <Column
                  footer={
                    <FormateCurrency
                      amount={reportTotalData.lossInINR}
                      className={"text-danger"}
                      icon={reportTotalData.icon}
                    />
                  }
                />
              </Row>
              <Row>
                <Column sortable footer="Total" colSpan={2} />
                <Column
                  footer={
                    <FormateCurrency
                      amount={reportTotalData.totalEarningInINR}
                      className={reportTotalData.className}
                      icon={reportTotalData.icon}
                    />
                  }
                />
                <Column
                  footer={
                    <FormateCurrency
                      amount={reportTotalData.totalSpendingInINR}
                      className={reportTotalData.className}
                      icon={reportTotalData.icon}
                    />
                  }
                />
                <Column
                  footer={
                    <FormateCurrency
                      amount={reportTotalData.totalProfitInINR}
                      className={reportTotalData.className}
                      icon={reportTotalData.icon}
                    />
                  }
                />
                <Column
                  footer={
                    <FormateCurrency
                      amount={reportTotalData.totalLossInINR}
                      className={reportTotalData.className}
                      icon={reportTotalData.icon}
                    />
                  }
                />
              </Row>
            </ColumnGroup>
          }
        >
          <Column
            sortable
            field="no"
            header="No."
            style={{ width: "3%" }}
          ></Column>
          <Column sortable field="countryName" header="Country Name"></Column>
          <Column
            sortable
            field="earningInINR"
            header="Earning"
            body={(item) =>
              amountBodyTemplate({
                amount: item.earningInINR,
                profit: item.profit,
                loss: item.loss,
              })
            }
          ></Column>
          <Column
            sortable
            field="spendingInINR"
            header="Spending"
            body={(item) =>
              amountBodyTemplate({
                amount: item.spendingInINR,
                profit: item.profit,
                loss: item.loss,
              })
            }
          ></Column>
          <Column
            sortable
            field="profit"
            header="Profit"
            body={(item) =>
              amountBodyTemplate({
                amount: item.profit,
                profit: item.profit,
                loss: item.loss,
              })
            }
          ></Column>
          <Column
            sortable
            field="loss"
            header="Loss"
            body={(item) =>
              amountBodyTemplate({
                amount: item.loss,
                profit: item.profit,
                loss: item.loss,
              })
            }
          ></Column>
        </DataTable>
      )}
    </Container>
  );
};

export default ShowReportDetails;
