import React, { useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import {
  createProjectReport,
  deleteProject,
  getProject,
  updateProject,
} from "../helper/backend_helper";
import MyButton from "../components/buttons/MyButton";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorBox from "../components/common/ErrorBox";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import MySpinner from "../components/common/MySpinner";
import BackButton from "../components/common/BackButton";

const Home = () => {
  const navigate = useNavigate();

  const [projects, setProjects] = useState([]);

  // loading
  const [fetching, setFetching] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  //add modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // edit modal
  const [showEdit, setShowEdit] = useState(false);

  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);

  const [selectedRecord, setSelectedRecord] = useState("");

  // confirm modal
  const [visibleDelete, setVisibleDelete] = useState(false);

  const handleDelete = async ({ _id }) => {
    try {
      setDeleteLoading(true);

      const response = await deleteProject({ _id });
      if (response.status === 200) {
        setProjects((prevData) => {
          const filteredData = prevData.filter((item) => item._id !== _id);
          return filteredData;
        });

        toast.success(response.data.message);
      }
    } catch (error) {
      console.log(error.message);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error(error.message);
      }
    } finally {
      setDeleteLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      setFetching(true);
      const response = await getProject();

      if (response.status === 200 && response?.data?.data) {
        const result = response.data?.data.map((record, index) => {
          return {
            _id: record._id,
            no: index + 1,
            name: record.name,
            createdAt: moment(record.createdAt).format("D MMMM YYYY h:mm A"),
            updatedAt: moment(record.updatedAt).format("D MMMM YYYY h:mm A"),
            action: (
              <div className="d-flex align-items-center" key={index}>
                <MyButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShowEdit();
                    setSelectedRecord(record);
                  }}
                  text={"Edit"}
                  size="small"
                  variant="success"
                  isLoading={updateLoading}
                />

                <MyButton
                  text={"Delete"}
                  variant="danger"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setVisibleDelete(true);
                    confirmDialog({
                      message: "Do you want to delete this record?",
                      header: "Delete Confirmation",
                      icon: "pi pi-info-circle",
                      acceptClassName: "p-button-danger",
                      footer: (
                        <div className="d-flex w-100 align-item-center">
                          <MyButton
                            text={"No"}
                            variant="danger"
                            className={"ms-auto"}
                            onClick={() => {
                              setVisibleDelete(false);
                            }}
                          />
                          <MyButton
                            isLoading={deleteLoading}
                            text={"Yes"}
                            variant="success"
                            onClick={async () => {
                              await handleDelete({ _id: record._id });
                              setVisibleDelete(false);
                            }}
                          />
                        </div>
                      ),
                    });
                  }}
                  className={"ms-2"}
                />
              </div>
            ),
          };
        });
        setProjects(result);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setFetching(false);
    }
  };

  const addFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      projectName: "",
      spendingFiles: [],
      earningFiles: [],
    },
    validationSchema: Yup.object({
      projectName: Yup.string("Project name must be a valid string").required(
        "Project name is required"
      ),
      spendingFiles: Yup.array().min(1, "At least one file is required."),
      earningFiles: Yup.array().min(1, "At least one file is required."),
    }),

    onSubmit: async (values, { resetForm }) => {
      try {
        setAddLoading(true);
        const formData = new FormData();

        formData.append("projectName", values.projectName);

        for (const file of values.earningFiles) {
          formData.append("earningFiles", file);
        }
        for (const file of values.spendingFiles) {
          formData.append("spendingFiles", file);
        }

        const responseCreate = await createProjectReport(formData);

        if (responseCreate.status === 200 || responseCreate.status === 201) {
          await fetchData();
          handleClose();
          toast.success(responseCreate.data.message);
        }
      } catch (error) {
        console.log(error.message);

        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error.message);
        }
      }
      setAddLoading(false);
    },
  });

  const editFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: selectedRecord.name || "",
    },
    validationSchema: Yup.object({
      name: Yup.string("Project name must be a valid string").required(
        "Project name is required"
      ),
    }),

    onSubmit: async (values) => {
      try {
        setUpdateLoading(true);
        const responseEdit = await updateProject({
          _id: selectedRecord?._id || "",
          values,
        });
        if (responseEdit.status === 200) {
          await fetchData();

          handleCloseEdit();
          toast.success(responseEdit.data.message);
        }
      } catch (error) {
        console.log(error);

        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error.message);
        }
      }
      setUpdateLoading(false);
    },
  });

  const onRowSelect = async ({ data }) => {
    navigate(`/projects/${data._id}`);
  };

  // fetch project
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container className="">
      <div className="p-3 bg-white d-flex align-items-center">
        <BackButton />
        <h5>All Projects</h5>
        <MyButton
          isLoading={addLoading}
          icon={<i className="ri-add-line" />}
          text={"Add"}
          variant="success"
          size="normal"
          onClick={handleShow}
          className={"ms-auto"}
        />
      </div>

      {fetching ? (
        <MySpinner />
      ) : (
        <DataTable
          size={"normal"}
          value={projects}
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: "50rem", fontSize: "14px" }}
          selectionMode="single"
          onRowSelect={onRowSelect}
        >
          <Column field="no" header="No" style={{ width: "3%" }}></Column>
          <Column field="name" header="App Name"></Column>
          <Column field="createdAt" header="Created Date"></Column>
          <Column field="updatedAt" header="Updated Date"></Column>
          <Column
            field="action"
            header="Action"
            style={{ width: "10%" }}
          ></Column>
        </DataTable>
      )}

      {/* add modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Add</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Form onSubmit={addFormik.handleSubmit}>
              <Col>
                <Form.Group controlId="projectName">
                  <Form.Label>Project Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter project name"
                    name="projectName"
                    value={addFormik.values.projectName}
                    onChange={addFormik.handleChange}
                    onBlur={addFormik.handleBlur}
                    list="projectList"
                  />
                  <datalist id="projectList">
                    {projects.map((item, index) => (
                      <option value={`${item.name}`} key={index} />
                    ))}
                  </datalist>
                  {addFormik.errors.projectName &&
                    addFormik.touched.projectName && (
                      <ErrorBox text={addFormik.errors.projectName} />
                    )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="earing-file">
                  <Form.Label>Earning Reports</Form.Label>
                  <Form.Control
                    type="file"
                    name="earningFiles"
                    onChange={(event) => {
                      addFormik.setFieldValue(
                        "earningFiles",
                        Array.from(event.currentTarget.files)
                      );
                    }}
                    placeholder="Enter project name"
                    multiple
                    accept=".csv, .xlsx"
                  />
                  {addFormik.errors.earningFiles &&
                    addFormik.touched.earningFiles && (
                      <ErrorBox text={addFormik.errors.earningFiles} />
                    )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="spending-file">
                  <Form.Label>Spending Reports</Form.Label>
                  <Form.Control
                    type="file"
                    name="spendingFiles"
                    onChange={(event) => {
                      addFormik.setFieldValue(
                        "spendingFiles",
                        Array.from(event.currentTarget.files)
                      );
                    }}
                    placeholder="Enter project name"
                    multiple
                    accept=".csv, .xlsx"
                  />
                  {addFormik.errors.spendingFiles &&
                    addFormik.touched.spendingFiles && (
                      <ErrorBox text={addFormik.errors.spendingFiles} />
                    )}
                </Form.Group>
              </Col>

              <Col className="d-flex justify-content-end">
                <MyButton
                  text={"Close"}
                  variant="light"
                  size="normal"
                  onClick={handleClose}
                />
                <MyButton
                  isLoading={addLoading}
                  type="submit"
                  text={"Upload"}
                  variant="success"
                  size="normal"
                  className={"ms-2"}
                />
              </Col>
            </Form>
          </Row>
        </Modal.Body>
      </Modal>

      {/* edit modal */}
      <Modal show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Edit</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Form onSubmit={editFormik.handleSubmit}>
              <Col>
                <Form.Group controlId="name">
                  <Form.Label>Project Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter project name"
                    name="name"
                    value={editFormik.values.name}
                    onChange={editFormik.handleChange}
                    onBlur={editFormik.handleBlur}
                  />
                  {editFormik.errors.name && editFormik.touched.name && (
                    <ErrorBox text={editFormik.errors.name} />
                  )}
                </Form.Group>
              </Col>

              <Col className="d-flex justify-content-end">
                <MyButton
                  text={"Close"}
                  variant="light"
                  size="normal"
                  onClick={handleCloseEdit}
                />
                <MyButton
                  isLoading={updateLoading}
                  type="submit"
                  text={"Update"}
                  variant="success"
                  size="normal"
                  className={"ms-2"}
                />
              </Col>
            </Form>
          </Row>
        </Modal.Body>
      </Modal>

      {/* confirm dialog */}
      <ConfirmDialog
        visible={visibleDelete}
        onHide={() => setVisibleDelete(false)}
      />
    </Container>
  );
};

export default Home;
