import axios from "axios";
import queryString from "query-string";
// default
axios.defaults.baseURL = process.env.REACT_APP_SERVER_BASE_URL;

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// content type
const token = JSON.parse(sessionStorage.getItem("authUser"))
  ? JSON.parse(sessionStorage.getItem("authUser")).token
  : null;
if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token;

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  get = (url, params) => {
    // content type
    const token = JSON.parse(sessionStorage.getItem("authUser"))
      ? JSON.parse(sessionStorage.getItem("authUser")).token
      : null;
    if (token)
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    let response;

    let paramKeys = [];

    if (params) {
      Object.keys(params).map((key) => {
        paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });

      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };

  /**
   * post given data to url
   */
  create = (url, data) => {
    const token = JSON.parse(sessionStorage.getItem("authUser"))
      ? JSON.parse(sessionStorage.getItem("authUser")).token
      : null;
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    return axios.post(url, data);
  };
  createWithFiles = (url, data) => {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";

    const token = JSON.parse(sessionStorage.getItem("authUser"))
      ? JSON.parse(sessionStorage.getItem("authUser")).token
      : null;
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    return axios.post(url, data);
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    const token = JSON.parse(sessionStorage.getItem("authUser"))
      ? JSON.parse(sessionStorage.getItem("authUser")).token
      : null;
    if (token)
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    return axios.patch(url, data);
  };

  put = (url, data) => {
    const token = JSON.parse(sessionStorage.getItem("authUser"))
      ? JSON.parse(sessionStorage.getItem("authUser")).token
      : null;
    if (token)
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    const token = JSON.parse(sessionStorage.getItem("authUser"))
      ? JSON.parse(sessionStorage.getItem("authUser")).token
      : null;
    if (token)
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    return axios.delete(url, { ...config });
  };

  deleteUsingQuery = (url, query) => {
    const token = JSON.parse(sessionStorage.getItem("authUser"))
      ? JSON.parse(sessionStorage.getItem("authUser")).token
      : null;
    if (token)
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    const urlQuery = queryString.stringify(query);
    return axios.delete(url + `?${urlQuery}`);
  };
}

export { APIClient, setAuthorization };
