import Home from "../pages/Home";
import Login from "../pages/Login";
import Register from "../pages/Register";
import ShowProjectDetails from "../pages/project/ShowProjectDetails";
import ShowReportDetails from "../pages/report/ShowReportDetails";

export const publicRoutes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
];

export const privateRoutes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/projects/:projectId",
    element: <ShowProjectDetails />,
  },
  {
    path: "/projects/:projectId/reports/:reportId",
    element: <ShowReportDetails />,
  },
];
