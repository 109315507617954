const FormateCurrency = ({ amount, currency = "₹", className, icon }) => {
  return (
    <div className="d-flex align-items-center">
      <span className={`${className}`}>
        {currency}
        {amount}/-
      </span>
      <span className={`line-height fs-4 ${className}`}>{icon}</span>
    </div>
  );
};

export default FormateCurrency;
