import React from "react";
import { useNavigate } from "react-router-dom";

const BackButton = ({ className }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };
  return (
    <div onClick={handleClick}>
      <i
        className={`ri-arrow-left-s-line pe-3 cursor-pointer fs-4 ${className}`}
      ></i>
    </div>
  );
};

export default BackButton;
