import { Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import "primereact/resources/themes/lara-light-indigo/theme.css"; // theme
import "primereact/resources/primereact.css"; // core css
import "primeicons/primeicons.css"; // icons
import "primeflex/primeflex.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { privateRoutes, publicRoutes } from "./routes";
import RootLayout from "./layout/rootLayout";
import "./assets/fonts/remixicon.css";

function App() {
  return (
    <>
      <Routes>
        {publicRoutes.map((route, key) => {
          return (
            <Route
              key={key}
              path={route.path}
              element={<RootLayout>{route.element}</RootLayout>}
            />
          );
        })}
        {privateRoutes.map((route, key) => {
          return (
            <Route
              key={key}
              path={route.path}
              element={<RootLayout>{route.element}</RootLayout>}
            />
          );
        })}
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
