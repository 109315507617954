import { APIClient } from "./api_helper";
import * as url from "./url_helper";

const api = new APIClient();

// projects
export const getProject = (data) => {
  return api.get(url.PROJECT, data);
};
export const getProjectById = ({ _id, query }) => {
  return api.get(url.PROJECT + `/${_id}`, query);
};

export const deleteProject = ({ _id, values }) => {
  return api.delete(url.PROJECT + `/${_id}`, values);
};

export const updateProject = ({ _id, values }) => {
  return api.update(url.PROJECT + `/${_id}`, values);
};

// project report
export const getProjectReport = (query) => {
  return api.get(url.PROJECT_REPORT, query);
};

export const createProjectReport = (data) => {
  return api.createWithFiles(url.PROJECT_REPORT, data);
};

export const deleteProjectReport = (query) => {
  return api.deleteUsingQuery(url.PROJECT_REPORT, query);
};
