import { InputText } from "primereact/inputtext";
import React from "react";

const SearchInput = ({ onChange, value }) => {
  return (
    <span className="p-input-icon-left ms-auto">
      <i className="pi pi-search" />
      <InputText
        onChange={onChange}
        value={value}
        placeholder="Search country"
        className="p-inputtext-sm"
        size={"small"}
      />
    </span>
  );
};

export default SearchInput;
