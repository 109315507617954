import React from "react";

const MyButton = ({
  type = "button",
  icon,
  isLoading,
  text,
  onClick,
  className,
  variant = "success",
  size = "normal",
}) => {
  const colorMapping = {
    success: "button-success",
    danger: "button-danger",
    light: "button-light",
  };

  const sizeMapping = {
    small: "button-sm",
    normal: "button-normal",
  };

  return (
    <button
      disabled={isLoading}
      type={type}
      onClick={onClick}
      className={`button ${sizeMapping?.[size]} ${colorMapping?.[variant]} ${className}`}
    >
      {isLoading ? (
        <div
          className={`spinner-border text-white button-spinner`}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <div className="d-flex">
          {icon && (
            <span className="me-2">
              <i className="ri-add-line"></i>
            </span>
          )}
          <span>{text}</span>
        </div>
      )}
    </button>
  );
};

export default MyButton;
