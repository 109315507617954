import React from "react";
import { Container, Navbar } from "react-bootstrap";

const TopNavbar = () => {
  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary bg-white">
        <Container>
          <Navbar.Brand href="/">Generate Report</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            {/* <Nav className="me-auto">
              <NavLink to={"/"}> home</NavLink> 
            </Nav> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default TopNavbar;
