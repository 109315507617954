import React from "react";

const MySpinner = () => {
  return (
    <div className=" d-flex align-items-center justify-content-center my-3">
      <div className="spinner-border " role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default MySpinner;
