import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteProjectReport,
  getProjectById,
} from "../../helper/backend_helper";
import { toast } from "react-toastify";
import MyButton from "../../components/buttons/MyButton";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Container } from "react-bootstrap";
import moment from "moment";
import MySpinner from "../../components/common/MySpinner";
import BackButton from "../../components/common/BackButton";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

const ShowProjectDetails = () => {
  const { projectId } = useParams();

  const navigate = useNavigate();

  const [reports, setReports] = useState([]);

  // loading
  const [fetching, setFetching] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  // confirm modal
  const [visibleDelete, setVisibleDelete] = useState(false);

  const fetchData = async () => {
    try {
      setFetching(true);
      const response = await getProjectById({ _id: projectId });

      if (response.status === 200 && response?.data?.data) {
        const report = response.data.data.reports.map((item, index) => {
          return {
            _id: item._id,
            createdAt: moment(item.createdAt).format("D MMMM YYYY h:mm A"),
            no: index + 1,
            name: `${response.data.data.name} (Report ${index + 1})`,
            action: (
              <div className="d-flex align-items-center">
                <MyButton
                  text={"Delete"}
                  variant="danger"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    // handleDelete({ _id: item._id });
                    setVisibleDelete(true);
                    confirmDialog({
                      message: "Do you want to delete this record?",
                      header: "Delete Confirmation",
                      icon: "pi pi-info-circle",
                      acceptClassName: "p-button-danger",
                      accept: () => {
                        handleDelete({ _id: item._id });
                      },
                      reject: () => {},

                      footer: (
                        <div className="d-flex w-100 align-item-center">
                          <MyButton
                            text={"No"}
                            variant="danger"
                            className={"ms-auto"}
                            onClick={() => {
                              setVisibleDelete(false);
                            }}
                          />
                          <MyButton
                            isLoading={deleteLoading}
                            text={"Yes"}
                            variant="success"
                            onClick={async () => {
                              await handleDelete({ _id: item._id });
                              setVisibleDelete(false);
                            }}
                          />
                        </div>
                      ),
                    });
                  }}
                  className={"ms-2"}
                />
              </div>
            ),
          };
        });
        setReports(report);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setFetching(false);
    }
  };

  const onRowSelect = async ({ data }) => {
    navigate(`/projects/${projectId}/reports/${data._id}`);
  };

  const handleDelete = async ({ _id }) => {
    try {
      setDeleteLoading(true);
      const response = await deleteProjectReport({ projectId, reportId: _id });

      if (response.status === 200) {
        toast.success(response.data.message);

        setReports((prevData) => {
          const filteredData = prevData.filter((item) => item._id !== _id);
          return filteredData;
        });
      }
    } catch (error) {
      console.log(error.message);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error(error.message);
      }
    }
    setDeleteLoading(false);
  };

  // fetch data
  useEffect(() => {
    fetchData();
  }, [projectId]);

  return (
    <Container>
      <div className="d-flex align-items-center w-100 p-3 bg-white">
        <BackButton />
        <h5>Project Reports</h5>
      </div>
      {fetching ? (
        <MySpinner />
      ) : (
        <DataTable
          size={"normal"}
          value={reports}
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: "50rem", fontSize: "14px" }}
          selectionMode="single"
          onRowSelect={onRowSelect}
        >
          <Column field="no" header="No" style={{ width: "3%" }}></Column>
          <Column field="name" header="Project Reports"></Column>
          <Column field="createdAt" header="Created Date"></Column>
          <Column
            field="action"
            header="Action"
            style={{ width: "10%" }}
          ></Column>
        </DataTable>
      )}

      {/* confirm dialog */}
      <ConfirmDialog
        visible={visibleDelete}
        onHide={() => setVisibleDelete(false)}
      />
    </Container>
  );
};

export default ShowProjectDetails;
